import { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { Button, Combobox, ScrollArea, useCombobox } from '@ui/core';
import { cx } from '@ui/utils';
import { IconChevronDown, IconPlus } from '@ui/icons';
import { getOrderFormsSchema } from '@api/buyers/order-form';

type OrderFormComboboxProps = {
  selectedOrderForm: { id: number | null; name: string; isNew: boolean } | null;
  onSelectOrderForm: (payload: { id: number; name: string; isNew: boolean }) => void;
  orderFormList: z.infer<typeof getOrderFormsSchema>;
  disabled?: boolean;
};

const OrderFormCombobox = ({
  selectedOrderForm,
  onSelectOrderForm,
  orderFormList: orderFormListFromProps,
  disabled = false,
}: OrderFormComboboxProps) => {
  const [orderFormList, setOrderFormList] = useState(orderFormListFromProps);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const options = useMemo(() => {
    const filteredOrderFormList = orderFormList.filter(orderForm =>
      orderForm.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredOrderFormList.map((orderForm, idx) => (
      <Combobox.Option
        key={orderForm.id}
        value={`${idx}`}
        className={cx('text-xs', orderForm?.id === selectedOrderForm?.id && 'bg-andisor-gray')}
      >
        {orderForm.name}
      </Combobox.Option>
    ));
  }, [orderFormList, selectedOrderForm, searchTerm]);

  const appendNewForm = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    if (!searchTerm) {
      combobox.focusSearchInput();
      toast.error('Enter a name to create a new form');
      return;
    }
    // create an auto-incremented id
    const newId =
      Math.max(...(orderFormList.length > 0 ? orderFormList.map(obj => obj.id) : [0])) + 1;
    setOrderFormList(prev => [
      ...prev,
      {
        id: newId,
        name: searchTerm,
      },
    ]);
    combobox.closeDropdown();
    setSearchTerm('');
    onSelectOrderForm({ id: newId, name: searchTerm, isNew: true });
  };

  return (
    <Combobox
      store={combobox}
      disabled={disabled}
      onOptionSubmit={val => {
        const selected = orderFormList[+val];
        if (selected) {
          onSelectOrderForm({
            id: selected.id,
            name: selected.name,
            isNew: false,
          });
          combobox.closeDropdown();
        }
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <div
          className={cx(
            'flex w-full cursor-pointer items-center justify-between overflow-hidden rounded border border-andisor-gray-400 px-2.5 py-4 text-left text-xs focus:outline-none',
            disabled && 'cursor-not-allowed bg-andisor-gray-50'
          )}
          onClick={evt => {
            evt.stopPropagation();
            if (!disabled) combobox.toggleDropdown();
          }}
        >
          {selectedOrderForm ? (
            <span className="mr-1 truncate">{selectedOrderForm.name}</span>
          ) : (
            <span className="mr-1 text-andisor-gray-600">Select an Order Form</span>
          )}
          <div className="flex items-center gap-4">
            <IconChevronDown size={16} />
          </div>
        </div>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          value={searchTerm}
          size="xs"
          classNames={{
            input: 'placeholder:text-xs text-xs focus:border-andisor-blue',
          }}
          onChange={event => setSearchTerm(event.currentTarget.value)}
          placeholder="Search..."
        />
        <Combobox.Options>
          <ScrollArea.Autosize type="always" mah={100}>
            {options.length === 0 ? (
              <Combobox.Empty className="flex flex-col items-center justify-center gap-3 text-xs">
                <span className="text-andisor-gray-500">
                  {orderFormList.length === 0 ? 'No forms available' : 'No such form exists'}
                </span>
                <Button size="xs" onClick={appendNewForm} className="mb-3" variant="plain">
                  <span>Create</span>
                  <IconPlus size={14} className="mb-0.5 ml-1" />
                </Button>
              </Combobox.Empty>
            ) : (
              options
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default OrderFormCombobox;
