import { useMemo, useState } from 'react';
import { IconTablePlus } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { type QueryError } from 'src/types/common';
import { z } from 'zod';
import { Button, Loader, Switch, Text } from '@ui/core';
import { api } from '@api';
import { useAuth } from '@hooks/useAuth';
import useIndentData from '@hooks/useIndentData';
import OrderFormCombobox from './OrderFormCombobox';

const AddToOrderFormPopup = ({
  productId,
  variantId,
  onAddSuccess,
}: {
  productId: string;
  variantId: string | undefined;
  onAddSuccess: () => void;
}) => {
  const { user } = useAuth();
  const retailerId = user?.activeRetailer?.id as number;

  const queryClient = useQueryClient();
  const { getEnablePlanYourOrder } = useIndentData();

  const [selectedOrderForm, setSelectedOrderForm] = useState<{
    id: number;
    name: string;
    isNew: boolean;
  } | null>(null);
  const [addAllVariants, setAddAllVariants] = useState<boolean>(false);

  const getOrderFormsQuery = useQuery({
    queryKey: [api.buyers.orderForm.getOrderForms.queryKey, { retailerId }],
    queryFn: () => api.buyers.orderForm.getOrderForms.query(retailerId),
    onError: (err: QueryError) => {
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
    },
    enabled: !!retailerId,
  });

  const getProductQuery = useQuery({
    queryKey: [api.sellers.inventory.getProduct.queryKey, { itemId: productId, retailerId }],
    queryFn: () => api.sellers.inventory.getProduct.query({ itemId: productId, retailerId }),
    onError: (err: QueryError) => {
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
    },
    enabled: !!productId,
  });

  const createOrderFormMutation = useMutation({
    mutationKey: [api.buyers.orderForm.createOrderForm.mutationKey],
    mutationFn: ({
      retailerId,
      name,
      addProductIds,
      addVariants,
    }: z.infer<typeof api.buyers.orderForm.createOrderForm.requestSchema>) =>
      api.buyers.orderForm.createOrderForm.mutation({
        retailerId,
        name,
        addProductIds,
        addVariants,
      }),
    onSuccess: () => {
      toast.success('Order form created successfully and variant(s) added');
      onAddSuccess();
      queryClient.invalidateQueries([api.buyers.orderForm.getOrderForms.queryKey]);
      queryClient.invalidateQueries([api.buyers.orderForm.getOrderForm.queryKey]);
    },
    onError: (err: QueryError) => {
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
    },
  });

  const updateOrderFormMutation = useMutation({
    mutationKey: [api.buyers.orderForm.updateOrderForm.mutationKey],
    mutationFn: ({
      orderFormId,
      addProductIds,
      addVariants,
    }: z.infer<typeof api.buyers.orderForm.updateOrderForm.requestSchema>) =>
      api.buyers.orderForm.updateOrderForm.mutation({
        orderFormId,
        addProductIds,
        addVariants,
      }),
    onSuccess: () => {
      toast.success('Variant(s) added to order form successfully!');
      onAddSuccess();
      queryClient.invalidateQueries([api.buyers.orderForm.getOrderForms.queryKey]);
      queryClient.invalidateQueries([api.buyers.orderForm.getOrderForm.queryKey]);
    },
    onError: (err: QueryError) => {
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
    },
  });

  const availableVariants = useMemo(() => {
    if (!getProductQuery.data) return [];
    return getProductQuery.data?.variants.filter(variant => getEnablePlanYourOrder(variant));
  }, [getEnablePlanYourOrder, getProductQuery.data]);

  const getItemsData = (
    variantId: string
  ): {
    addProductIds: string[];
    addVariants: { productId: string; variantId: string }[];
  } => {
    const data: {
      addProductIds: string[];
      addVariants: { productId: string; variantId: string }[];
    } = {
      addProductIds: [],
      addVariants: [],
    };

    const allVariantsAreAvailable =
      getProductQuery.data?.variants.length === availableVariants.length;

    // we are adding all variants
    if (addAllVariants) {
      if (allVariantsAreAvailable) {
        // all variants are non indent so add product id
        data.addProductIds.push(productId);
      } else {
        // only some variants are non indent so filter the list
        availableVariants.forEach(variant =>
          data.addVariants.push({ productId, variantId: variant.id })
        );
      }
    } else {
      // we are addding a single variant
      data.addVariants.push({ productId, variantId });
    }

    return data;
  };

  const onCreateOrUpdateOrderForm = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();

    if (!variantId) return;

    if (!selectedOrderForm) {
      toast.error('Please select an order form');
      return;
    }

    if (addAllVariants && availableVariants.length === 0) {
      toast.error('Certain variants are not eligible to be added to the order form');
      return;
    }

    const data = getItemsData(variantId);

    if (selectedOrderForm.isNew) {
      // create order form
      createOrderFormMutation.mutate({
        retailerId,
        name: selectedOrderForm.name,
        addProductIds: data.addProductIds,
        addVariants: data.addVariants,
      });
    } else {
      // update order form
      updateOrderFormMutation.mutate({
        orderFormId: selectedOrderForm.id,
        addProductIds: data.addProductIds,
        addVariants: data.addVariants,
      });
    }
  };

  const onSelectOrderForm = ({ id, name, isNew }: { id: number; name: string; isNew: boolean }) => {
    setSelectedOrderForm({ id, name, isNew });
  };

  return getProductQuery?.isFetching || getOrderFormsQuery.isFetching ? (
    <div className="flex h-full items-center justify-center">
      <Loader size="sm" />
    </div>
  ) : (
    <>
      {getOrderFormsQuery.data && (
        <>
          <OrderFormCombobox
            selectedOrderForm={selectedOrderForm}
            onSelectOrderForm={onSelectOrderForm}
            orderFormList={getOrderFormsQuery.data}
            disabled={createOrderFormMutation.isLoading || updateOrderFormMutation.isLoading}
          />
          <div className="mt-2 flex items-center justify-end gap-2">
            <Text className="text-xs">Add all variants?</Text>
            <Switch
              checked={addAllVariants}
              onChange={e => {
                const isChecked = e.currentTarget.checked;
                setAddAllVariants(isChecked);
              }}
              size="xs"
              disabled={createOrderFormMutation.isLoading || updateOrderFormMutation.isLoading}
            />
          </div>
          <div className="mt-10 flex items-center justify-center">
            <Button
              size="sm"
              className="mt-4"
              rightSection={
                <div className="flex items-center">
                  <IconTablePlus size={14} />
                </div>
              }
              onClick={onCreateOrUpdateOrderForm}
              loading={createOrderFormMutation.isLoading || updateOrderFormMutation.isLoading}
            >
              Add
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AddToOrderFormPopup;
