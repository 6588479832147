import { addDays, format, isEqual, startOfToday } from 'date-fns';
import { Controller, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { Combobox, NumberInput, Tooltip, useCombobox } from '@ui/core';
import { cx } from '@ui/utils';
import { IconCheck, IconChevronDown } from '@ui/icons';
import ColorPill from '@components/ColorPill/ColorPill';
import { getMaskedStock } from '@components/ProductDataGrid/utils';
import { useTableRowContext } from '@components/Table/RowWrapper';
import { TD } from '@components/Table/TD';
import { TR } from '@components/Table/TR';
import { api } from '@api';
import { getCurrencySymbol } from '@utils/currency';
import { convertNumberToPriceFormat, getDeliveryWindowDates, getWHS } from '@utils/functions';
import { allowNumberStringOnly, convertStringToNumber } from '@utils/numberInput';
import { QuickBuyFormSchemaType } from './QuickBuyModal';
import { QuickBuyTable_TD } from './QuickBuyTable';

interface QuickBuyTableRowProps {
  row: QuickBuyFormSchemaType['quantities'][number];
  form: UseFormReturn<QuickBuyFormSchemaType>;
  product: z.infer<typeof api.sellers.inventory.getProduct.schema>;
}

const QuickBuyTableRow = ({ form, row, product }: QuickBuyTableRowProps) => {
  const tableRowContext = useTableRowContext();
  const variantIndex = tableRowContext.rowIndex;

  const currentRow = form.watch(`quantities.${variantIndex}`);
  const deliveryDateOfRow = currentRow.deliveryDate;

  const combobox = useCombobox();

  const getPriceOfVariant = () => {
    const discount = product?.discount ?? 0;
    const RRP = row.price;
    return product?.persona
      ? getWHS({
          discountType: 'persona',
          discount,
          RRP,
          additionalDiscount: product.additionalDiscount ?? 0,
        })
      : getWHS({
          discountType: 'global',
          discount,
          RRP,
        });
  };

  const getTotalQuantityForAttribute = () => {
    return currentRow.variants.reduce((t = 0, current) => {
      return t + convertStringToNumber(current.quantity);
    }, 0);
  };

  const getTotalPrice = () => {
    return currentRow.variants.reduce((t = 0, current) => {
      // assuming that the price of all the atomic variants are
      // the same as the first atomic variant
      return t + convertStringToNumber(current.quantity) * getPriceOfVariant();
    }, 0);
  };

  return (
    <>
      <TR>
        {product.primaryAttribute !== null && (
          <TD className={QuickBuyTable_TD.colour}>
            <div className="flex items-center gap-2">
              {row.attribute ? (
                <>
                  {row.colourPill && <ColorPill color={row?.colourPill?.hex} />}
                  <Tooltip label={row.attribute} position="top">
                    <span className="w-[165px] truncate">{row.attribute}</span>
                  </Tooltip>
                </>
              ) : (
                '-'
              )}
            </div>
          </TD>
        )}

        <TD className={QuickBuyTable_TD.deliveryDate}>
          <Combobox
            store={combobox}
            width={250}
            position="bottom-start"
            withArrow
            onOptionSubmit={() => combobox.closeDropdown()}
          >
            <Combobox.Target>
              <button
                type="button"
                onClick={() => {
                  combobox.toggleDropdown();
                }}
                className="flex items-center gap-2 rounded-md border-none bg-white p-1 px-2 ring-1 ring-slate-300 focus:outline-none focus:ring-2 focus:ring-blue-600"
              >
                {deliveryDateOfRow
                  ? format(deliveryDateOfRow, 'dd / MMM / yyyy')
                  : 'Click To Select'}
                <IconChevronDown className="h-4 w-4" />
              </button>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>
                {getDeliveryWindowDates({
                  start: startOfToday(),
                  end: addDays(startOfToday(), 90),
                }).map(day => (
                  <Combobox.Option
                    key={day.toDateString()}
                    value={day.toDateString()}
                    className=" flex w-[200px] items-center gap-2 px-2 py-1 hover:bg-slate-200"
                    onClick={() => form.setValue(`quantities.${variantIndex}.deliveryDate`, day)}
                  >
                    <IconCheck
                      className={cx('h-4 w-4', {
                        invisible: deliveryDateOfRow ? !isEqual(day, deliveryDateOfRow) : true,
                      })}
                    />
                    <span>{format(day, 'dd / MMM / yyyy')}</span>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </TD>

        <TD className={QuickBuyTable_TD.price}>
          {getCurrencySymbol()}
          {convertNumberToPriceFormat(getPriceOfVariant())}
        </TD>

        {row.variants.map((atomicVariant, index) => {
          const quantity = form.watch(`quantities.${variantIndex}.variants.${index}.quantity`);

          const stockAvailable =
            form.watch(`quantities.${variantIndex}.variants.${index}.stockAvailable`) ?? 0;
          return (
            <TD key={atomicVariant.sku} className={QuickBuyTable_TD.size}>
              <Controller
                name={`quantities.${variantIndex}.variants.${index}.quantity`}
                control={form.control}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    type="text"
                    value={field.value || quantity}
                    onChange={val => {
                      field.onChange(allowNumberStringOnly(`${val}`));
                    }}
                    min={0}
                    allowDecimal={false}
                    error={convertStringToNumber(quantity) > stockAvailable}
                    classNames={{
                      input: 'w-full text-right disabled:cursor-not-allowed disabled:opacity-60',
                    }}
                  />
                )}
              />
            </TD>
          );
        })}

        <TD className={QuickBuyTable_TD.totalQty}>{getTotalQuantityForAttribute()}</TD>
        <TD className={QuickBuyTable_TD.totalPrice}>
          {getCurrencySymbol()}
          {convertNumberToPriceFormat(getTotalPrice())}
        </TD>
      </TR>

      <TR>
        {product.primaryAttribute !== null && <TD className={QuickBuyTable_TD.colour}></TD>}
        <TD className={QuickBuyTable_TD.deliveryDate}></TD>
        <TD className={QuickBuyTable_TD.price}>
          <span className="ml-8">Available Stock</span>
        </TD>

        {row.variants.map((atomicVariant, index) => {
          return (
            <TD key={atomicVariant.sku + '-stock-item-' + index} className={QuickBuyTable_TD.size}>
              <span className="px-1.5">{getMaskedStock(atomicVariant?.stockAvailable || 0)}</span>
            </TD>
          );
        })}

        <TD className={QuickBuyTable_TD.totalQty}>
          {getMaskedStock(
            row.variants.reduce((total = 0, curr) => {
              return total + (curr.stockAvailable ?? 0);
            }, 0)
          )}
        </TD>
        <TD className={QuickBuyTable_TD.totalPrice}>-</TD>
      </TR>
    </>
  );
};

export default QuickBuyTableRow;
