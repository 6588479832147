import { useCallback } from 'react';
import { z } from 'zod';
import { Variant } from '@pages/product/[itemId]';
import { Discounts } from '@components/buyers/products/ProductPriceTable';
import { PricingOption } from '@components/ProductDataGrid/cells/PricingCell/PricingTable';
import { PricingTableDiscountsType } from '@features/buyers/product/PlanYourOrder/PlanYourOrderForm';
import { collectionsSchema } from '@api/sellers/inventory';
import { isOutOfOrderWindow } from '@utils/functions';

const useIndentData = () => {
  const getEnablePlanYourOrder = useCallback((selectedVariant: Variant): boolean => {
    if (!selectedVariant) return false;
    const { indentOnly, collections } = selectedVariant;
    // enabled for all non-indent variants
    if (!indentOnly) {
      return true;
    } else {
      const activeCollection = collections?.find(collection => {
        if (collection.type === 'indent') {
          const { orderStartDate, orderEndDate } = collection;
          if (orderStartDate && orderEndDate) {
            const window = { startDate: new Date(orderStartDate), endDate: new Date(orderEndDate) };
            if (!isOutOfOrderWindow(window)) {
              return true; // has active indent collection
            }
          }
        }
      });
      return activeCollection ? true : false;
    }
  }, []);

  const shouldShowDiscountInProductTable = useCallback((discount: Discounts): boolean => {
    if (discount.indentOnly) {
      if (discount.type === 'collection') {
        // indent discount only
        return discount.isIndentCollectionDiscountType ? true : false;
      } else {
        // allow global/persona
        return true;
      }
    } else {
      // allow all for non-indent variants
      return true;
    }
  }, []);

  const shouldShowDiscountInOrderFormTable = useCallback(
    (pricingOption: PricingOption): boolean => {
      if (pricingOption.indentOnly) {
        if (!pricingOption.orderStartDate || !pricingOption.orderEndDate) return false;
        return (
          pricingOption?.type === 'indent' &&
          !isOutOfOrderWindow({
            startDate: new Date(pricingOption.orderStartDate),
            endDate: new Date(pricingOption.orderEndDate),
          })
        );
      }
      return true;
    },
    []
  );

  const shouldShowEmptyProductTable = useCallback(
    (discounts: Discounts[], indentOnly: boolean): boolean => {
      if (indentOnly) {
        // must have atleast one indent discount
        const indentDiscount = discounts?.find(
          discounts => discounts.isIndentCollectionDiscountType
        );
        return indentDiscount ? false : true;
      } else {
        // hide for non-indent variants
        return false;
      }
    },
    []
  );

  const shouldShowDiscountInPlanYourOrderTable = useCallback(
    (discounts: PricingTableDiscountsType): boolean => {
      if (discounts.indentOnly) {
        if (discounts.type === 'COLLECTION') {
          // indent discount only
          return discounts.isIndentCollectionDiscountType ? true : false;
        } else {
          // allow global/persona
          return true;
        }
      } else {
        // allow all for non-indent variants
        return true;
      }
    },
    []
  );

  const getIsInactiveOrderWindow = useCallback(
    (collection: z.infer<typeof collectionsSchema>[0]): boolean => {
      const { orderStartDate, orderEndDate } = collection;
      if (orderStartDate && orderEndDate) {
        const window = { startDate: new Date(orderStartDate), endDate: new Date(orderEndDate) };
        return isOutOfOrderWindow(window);
      }
      return false;
    },
    []
  );

  const shouldEnablePricingOption = useCallback((discounts: PricingTableDiscountsType): boolean => {
    // only enable indent discout type for indent variant
    if (discounts.indentOnly && !discounts.isIndentCollectionDiscountType) {
      return false;
    }
    // disable inactive collection
    if (discounts.type === 'COLLECTION' && discounts.inactiveOrderWindow) {
      return false;
    }

    return true;
  }, []);

  return {
    getEnablePlanYourOrder,
    shouldShowDiscountInPlanYourOrderTable,
    shouldEnablePricingOption,
    getIsInactiveOrderWindow,
    shouldShowDiscountInProductTable,
    shouldShowEmptyProductTable,
    shouldShowDiscountInOrderFormTable,
  };
};

export default useIndentData;
