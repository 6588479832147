import { useEffect, useState } from 'react';
import { FloatingPosition } from '@mantine/core';
import { IconTablePlus } from '@tabler/icons-react';
import { ActionIcon, Button, ButtonProps, Popover } from '@ui/core';
import { cx } from '@ui/utils';
import AddToOrderFormPopup from './AddToOrderFormPopup';

const OrderFormPopupItem = ({
  productId,
  variantId,
  index,
  className,
  isParentOpen,
  isCustomTarget = false,
  onAddSuccess,
  customTargetProps = {},
  position = 'right',
  closeOnClickOutside,
  unControlled = false,
}: {
  productId: string;
  variantId: string | undefined;
  index?: number;
  className?: string;
  isParentOpen: boolean;
  isCustomTarget?: boolean;
  customTargetProps?: ButtonProps;
  onAddSuccess?: () => void;
  position?: FloatingPosition;
  closeOnClickOutside?: boolean;
  unControlled?: boolean;
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isParentOpen) setIsPopupOpen(false);
  }, [isParentOpen]);

  const onSuccess = () => {
    onAddSuccess?.();
    setIsPopupOpen(false);
  };

  const onClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setIsPopupOpen(prev => !prev);
  };

  return (
    <Popover
      opened={unControlled ? undefined : isPopupOpen}
      key={index}
      width={200}
      offset={5}
      position={position}
      zIndex={900}
      withArrow
      withinPortal
      closeOnClickOutside={closeOnClickOutside}
    >
      <Popover.Target>
        {!isCustomTarget ? (
          <ActionIcon
            variant="plain"
            label="Add to Order Form"
            style={{ transitionDelay: `${index ?? 0 * 25}ms` }}
            className={cx('bg-andisor-blue', className)}
            tooltipProps={{
              position: 'left',
              withinPortal: true,
            }}
            onClick={onClick}
          >
            <IconTablePlus />
          </ActionIcon>
        ) : (
          <Button onClick={onClick} {...customTargetProps} />
        )}
      </Popover.Target>
      <Popover.Dropdown className="flex h-full max-h-[220px] flex-col gap-4 p-0 px-4 py-6 font-body text-sm text-andisor-navy shadow-md">
        <AddToOrderFormPopup productId={productId} variantId={variantId} onAddSuccess={onSuccess} />
      </Popover.Dropdown>
    </Popover>
  );
};

export default OrderFormPopupItem;
